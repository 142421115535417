import './bootstrap';

// Set the DataTables defaults
$.extend(true, $.fn.dataTable.defaults, {
    // Use custom layout for pagination controls
    dom: '<"row"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"f>><"table-responsive"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
    // Customize the pagination control buttons
    language: {
        paginate: {
            next: '<i class="fa fa-angle-right"></i>', // Use Font Awesome icon for "next" button
            previous: '<i class="fa fa-angle-left"></i>', // Use Font Awesome icon for "previous" button
        },
    },
});
