import 'bootstrap';
import jQuery from 'jquery';
import swal from 'sweetalert2';
import 'datatables.net-bs5';
import moment from 'moment';


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.$ = window.jQuery = jQuery;

// resources/js/bootstrap.js
// import flatpickr from 'flatpickr';
// import rangeplugin from 'flatpickr/dist/plugins/rangePlugin';

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// resources/js/bootstrap.js

const Swal = window.Swal = swal;

// resources/js/bootstrap.js

import flatpickr from 'flatpickr';
import rangeplugin from 'flatpickr/dist/plugins/rangePlugin';

const rangePlugin = window.rangePlugin = rangeplugin;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

require('jquery.redirect');
require('select2');
$('.select2').select2();
